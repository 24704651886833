.App {
  background-color: #282c34;
  background-image: url('./Assets/GameplayBG.png');
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.Menu {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10rem auto;
}